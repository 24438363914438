import React from "react"
import { graphql } from "gatsby"
import {
  Section,
  SectionHeading,
  SectionLedeDiv,
} from "../../../styledComponents/section"
import LayoutNoStripe from "../../../components/layoutNoStripe"

const Heading = ({ level = 1, location, children }) => (
  <SectionHeading as={`h${level}`} level={level} location={location}>
    {children}
  </SectionHeading>
)

const DistanceHealingPayment = ({ data, location }) => {

  return (
    <>
      <LayoutNoStripe displayNavBorder location={location}>
        <Section>
          <Heading level={1}>
            Payment 
          </Heading>
          <SectionLedeDiv>
            <p>Please select from one of the prices below to make a payment for your session with Karina.</p>
          </SectionLedeDiv>
          <script async src="https://js.stripe.com/v3/pricing-table.js"></script>
          <stripe-pricing-table pricing-table-id="prctbl_1R2X50IfnzsCIRSOMxasFfjz"
            publishable-key="pk_live_LlCHGco3jggLnSWdhLCvfe59">
          </stripe-pricing-table>
        </Section>
      </LayoutNoStripe>
    </>
  )
}

export default DistanceHealingPayment

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
    wpPage(slug: { eq: "thank-you" }) {
      title
      content
    }
  }
`